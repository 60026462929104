import React, {useState} from 'react';
import {RiArrowLeftLine, RiSearchLine} from 'react-icons/ri';
import {BRAND_LOGO_DIMENSIONS, getBrandedHeightAndWidth} from 'spekit-datalayer';
import {
  AskAnExpert,
  CreateMenu,
  NavBorder,
  NotificationBell,
  useCustomBranding,
} from 'spekit-shared-components';
import {
  Flex,
  DSTooltip as Tooltip,
  Box,
  useBreakpointValue,
  DSButton as Button,
  Icon,
  Image,
} from 'spekit-ui';
import SpekitLogo from '../../images/new-spekit-logo.svg';
import * as permissions from '../../utils/permissions';
import {AppNavigationButtons} from './AppNavigationButtons';
import {AppSearch} from './AppSearch';
import {useAppNavigation} from './useAppNavigation';

export const AppNavigation = () => {
  const {
    flags,
    handleContentCreatorSelection,
    history,
    notificationCount,
    onAskExpert,
    session,
  } = useAppNavigation();

  const {data: branding} = useCustomBranding(); // data will always be present since it's fetched in theme provider
  const brandLogoUrl = branding?.images.brandLogoUrl;

  const isSupportEmailEnabled = session.company.is_support_email_enabled;
  const isViewer = session.role === 'Viewer';

  const [isSearchButtonSelected, setIsSearchButtonSelected] = useState(false);
  const isIconVariant = useBreakpointValue({base: true, 'tablet-md': false});

  if (isSearchButtonSelected) {
    return (
      <>
        <Flex px={32} height='70px' alignItems='center' justifyContent='center' gap={6}>
          <Button
            tooltipLabel='Back'
            variant='icon'
            colorScheme='transparent'
            size='medium'
            aria-label='Go back'
            icon={<Icon as={RiArrowLeftLine} />}
            onClick={() => setIsSearchButtonSelected(false)}
          />
          <AppSearch />
        </Flex>
        <NavBorder />
      </>
    );
  }

  return (
    <>
      <Flex px={32} height='70px' alignItems='center' justifyContent='space-between'>
        <Flex gap={{base: 16, 'mobile-lg': 46}} alignItems='center'>
          <Tooltip label='Refresh'>
            <Box as='span' cursor='pointer' lineHeight={0} mt={2}>
              <Image
                {...getBrandedHeightAndWidth(
                  BRAND_LOGO_DIMENSIONS,
                  {width: '100px', height: '46px'},
                  brandLogoUrl
                )}
                objectFit='contain'
                aria-label='Refresh Spekit'
                src={brandLogoUrl || SpekitLogo}
                onClick={() => history.push('/app')}
                data-testid={brandLogoUrl ? 'brand-logo' : 'spekit-logo'}
              />
            </Box>
          </Tooltip>
          <AppNavigationButtons />
        </Flex>
        <Flex gap={16} alignItems='center'>
          <Box display={{base: 'none', 'tablet-sm': 'revert'}}>
            <AppSearch />
          </Box>
          <Button
            variant='icon'
            colorScheme='outlined'
            size='medium'
            icon={<Icon as={RiSearchLine} />}
            aria-label='Search'
            tooltipLabel='Search'
            display={{base: 'flex', 'tablet-sm': 'none'}}
            onClick={() => setIsSearchButtonSelected(true)}
          />
          {isViewer ? (
            <AskAnExpert
              isSupportEmailEnabled={isSupportEmailEnabled}
              onAskExpert={onAskExpert}
              size='medium'
              variant={isIconVariant ? 'icon' : 'contained'}
            />
          ) : (
            <CreateMenu
              onCreate={handleContentCreatorSelection}
              canCreate={permissions.hasSomeOf(['businessterms.add_own'])}
              role={session.role}
              flags={flags}
              size='medium'
              variant={isIconVariant ? 'icon' : 'contained'}
            />
          )}
          <NotificationBell
            notificationCount={notificationCount}
            handleNavigation={() => history.push('/app/notifications')}
          />
        </Flex>
      </Flex>
      <NavBorder color={branding?.colors.primary} />
    </>
  );
};
