import React from 'react';
import {Grid, GridItem, Image, Flex, Text, Box, Loader, Heading} from 'spekit-ui';
import layoutImage from '../../../../images/login-background.svg';
import productImage from '../../../../images/product-image.png';
import SpekitLogo from '../../../../images/new-logo-white.svg';

interface ILoginLayoutProps {
  image?: string;
  children: React.ReactNode;
  hideQuote?: boolean;
  hideImage?: boolean;
  isPageLoading?: boolean;
  loaderText?: string;
}
const LoginLayout = ({
  image,
  children,
  hideQuote,
  hideImage,
  isPageLoading,
  loaderText,
}: ILoginLayoutProps) => {
  if (isPageLoading) {
    return (
      <Flex
        direction='column'
        gap={24}
        justifyContent='center'
        alignItems='center'
        py={60}
      >
        <Loader size={8} />
        {loaderText && (
          <Text
            data-testid='redirecting-text'
            variant='caption1'
            fontWeight='semibold'
            textAlign='center'
          >
            {loaderText}
          </Text>
        )}
      </Flex>
    );
  }
  return (
    <Grid column={2} w='100%' h='100vh' autoFlow='column' autoColumns='50%'>
      <GridItem
        bgImage={image ? image : layoutImage}
        bgRepeat='no-repeat'
        bgSize='cover'
        h='100%'
        w='100%'
      >
        <Flex direction='column' gap={24}>
          <Flex direction='column' mt={32} px={64} gap={32} maxW='80%'>
            <Flex pt={12}>
              <Image src={SpekitLogo} h='28px' />
            </Flex>
            {!hideQuote && (
              <Flex direction='column' gap={20}>
                <Heading as='h4' fontWeight='semibold' color='neutral.0' lineHeight={1.4}>
                  “Spekit helps me instantly find answers to my questions. I love how I
                  can get immediate access to any updates or changes.”
                </Heading>
                <Flex direction='column'>
                  <Text fontWeight='bold' variant='body2' color='neutral.0'>
                    - Trina, Service Advisor at Road Scholar
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Box flex={1} />
          {!hideImage && (
            <>
              <Box w='100%'>
                <Image src={productImage} w='90%' />
              </Box>
            </>
          )}
          <Box flex={1} />
        </Flex>
      </GridItem>

      <GridItem letterSpacing={0}>
        <Flex justifyContent='center' alignItems='center' w='100%' direction='column'>
          {children}
        </Flex>
      </GridItem>
    </Grid>
  );
};
export default LoginLayout;
