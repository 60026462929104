import {useState, useEffect} from 'react';
import {
  Flex,
  Image,
  Link as DSLink,
  Input,
  Icon,
  Heading,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from 'spekit-ui';
import {appsCompat, logging} from 'spekit-datalayer';
import {useLocation} from 'react-router-dom';
import {IAppCompatibilityStatusResponse, IRenderTableCell} from 'spekit-types';
import AppCompatibilityOTP from './appCompatibilityOTP';
import salesforce_cpq from '../icons/cpq.svg';
import ncino from '../icons/ncino.svg';
import salesforce_icon from '../icons/salesforce.svg';
import salesloft from '../icons/salesloft.svg';
import salesforce_financial_cloud from '../icons/financialforce.svg';
import outreach from '../icons/outreach.svg';
import seismic from '../images/integrations/Seismic/seismic-logo-static.svg';
import spekit from '../icons/spekit.svg';
import SpekitLogo from '../images/new-logo-white.svg';
import hubspotcrm from '../icons/hubspotoperations.svg';
import hubspotcms from '../icons/hubspotcms.svg';
import hubspotmarketing from '../icons/hubspotmarketing.svg';
import hubspotsaleshub from '../icons/hubspotsales.svg';
import hubspotservice from '../icons/hubspotservice.svg';
import clickup from '../icons/clickup.svg';
import zoominfo from '../icons/zoominfo.svg';
import chorus from '../icons/chorus.svg';
import zendesk from '../icons/zendesk.svg';
import tableau from '../icons/tableau.svg';
import microsoft_dynamics from '../icons/microsoft365.svg';
import powerbi from '../icons/powerbi.svg';
import gainsight from '../icons/gainsight.svg';
import linkedin_sales_nav from '../icons/linkedin_sales_nav.svg';
import box from '../icons/box.jpg';
import loom from '../icons/loom.svg';
import typeform from '../icons/typeform.svg';
import vimeo from '../icons/vimeo.svg';
import air from '../icons/air.png';
import zendesk_gather from '../icons/zendesk_gather.png';
import zendesk_chat from '../icons/zendesk_chat.png';
import zendesk_explore from '../icons/zendesk_explore.png';
import servicenow from '../icons/servicenow.svg';
import catalyst from '../icons/catalyst.svg';
import {RiRefreshLine} from 'react-icons/ri';
import DescriptionWithLinks from './descriptionWithLinks';

const dotStyle = {
  height: '15px',
  width: '15px',
  marginLeft: '10px',
  borderRadius: '50%',
  display: 'inline-block',
};

const getColor = (percentage: number) => {
  if (percentage >= 80) {
    return 'green';
  } else if (percentage >= 50) {
    return 'gold';
  } else {
    return 'red';
  }
};

export const RenderTableCell = (props: IRenderTableCell) => {
  const {features} = props;
  return (
    <Td style={{width: '8em', textAlign: 'center'}}>
      {features ? (
        <div>
          <span
            data-testid='dot'
            style={{
              ...dotStyle,
              backgroundColor: getColor(features.passing_percentage),
            }}
          />
        </div>
      ) : (
        '-'
      )}
    </Td>
  );
};
const iconsList = {
  salesforce_service_cloud: salesforce_icon,
  salesforce_sales_cloud: salesforce_icon,
  salesforce_report: salesforce_icon,
  salesforce_cpq,
  salesforce_financial_cloud,
  salesloft,
  seismic,
  hubspot_sales_hub: hubspotsaleshub,
  hubspot_service_hub: hubspotservice,
  hubspot_marketing_hub: hubspotmarketing,
  hubspot_cms_hub: hubspotcms,
  hubspot_crm_hub: hubspotcrm,
  spekit,
  outreach,
  ncino,
  zoominfo,
  chorus,
  zendesk,
  tableau,
  microsoft_dynamics,
  powerbi,
  gainsight,
  linkedin_sales_nav,
  box,
  servicenow,
  clickup,
  vimeo,
  loom,
  typeform,
  air,
  zendesk_gather,
  zendesk_explore,
  zendesk_chat,
  catalyst,
};

const AppCompatibilityStatus = () => {
  const location = useLocation<{appsCompatUser: boolean}>();
  const [status, setStatus] = useState({});
  const [lastUpdateDate, setlastUpdateDate] = useState('');
  const [currentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const pageSize = 1000;

  const [validOTP, setValidOTP] = useState(
    (location.state && location.state.appsCompatUser) || false
  );

  useEffect(() => {
    const getAppStatus = async () => {
      try {
        const response: Array<IAppCompatibilityStatusResponse> =
          await appsCompat.appsCompatStatus();
        const appStatus = {};
        const deletedApps = [];
        let maxUpdatedAt: any = null;
        response.forEach((item: IAppCompatibilityStatusResponse) => {
          let appName = item.app_name;
          let featureName = item.feature_name;
          let is_app_deleted = item.is_app_deleted;
          let is_feature_deleted = item.is_deleted;
          if (is_app_deleted) {
            deletedApps.push(appName);
          } else if (!is_feature_deleted) {
            if (!appStatus[appName]) {
              appStatus[appName] = [];
              appStatus[appName]['status'] = [];
              appStatus[appName]['percentage'] = [];
              appStatus[appName]['description'] = item.description;
            }
            if (!appStatus[appName][featureName]) {
              appStatus[appName][featureName] = item;
              appStatus[appName]['status'].push(item.status);
              appStatus[appName]['percentage'].push(item.passing_percentage);
            }
            maxUpdatedAt = item.max_date;
          }
        });

        setStatus(appStatus);
        setlastUpdateDate(new Date(maxUpdatedAt).toLocaleDateString());
      } catch (e) {
        logging.capture(e as Error);
      }
    };
    if (validOTP) {
      getAppStatus();
    }
  }, [validOTP]);

  const getDescription = (key: string) => {
    return status[key]['description'] === '' ? 'NA' : status[key]['description'];
  };

  const setValidatedUser = (value: boolean) => {
    setValidOTP(value);
  };

  return (
    <Flex direction='row'>
      <Flex
        background={'linear-gradient(90deg, #CB2BBA 0%, #653FE6 100%)'}
        bgRepeat='no-repeat'
        bgSize='cover'
        bgPosition='left'
        width='100vw'
        height='70px'
      >
        <Flex
          direction='column'
          color='#637280'
          position='absolute'
          top='15px'
          left='33px'
          bottom='27px'
          textAlign='left'
        >
          <Image src={SpekitLogo} h='28px' />
        </Flex>

        {validOTP ? (
          Object.keys(status).length > 0 && (
            <Flex
              direction='column'
              boxShadow='0px 0px 32px 0px rgba(0, 0, 0, 0.2)'
              width='1182px'
              height='742px'
              backgroundColor='white'
              boxSizing='border-box'
              margin='10vh auto 90px'
              overflow='auto'
              borderRadius='14px'
            >
              <Table mb='-90px'>
                <Tr>
                  <Td borderBottom='none' padding='4px'>
                    <Heading as='h4' m={26} color='neutral.800' fontWeight='bold'>
                      Spekit app-compatibility status
                    </Heading>
                  </Td>
                  <Td borderBottom='none' rowSpan={2}>
                    <Input
                      w='400px'
                      ml={341}
                      type='text'
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder=' Search Application...'
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td borderBottom='none'>
                    <Flex alignItems='center' mt='-35px' ml='4px'>
                      <Icon as={RiRefreshLine} h={4} w={4} />
                      <Text fontSize='small' ml='3px'>
                        Update daily. Last updated {lastUpdateDate}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              </Table>

              <Flex
                direction='column'
                width='1157px'
                height='742px'
                background='white'
                color='#FFFFFF'
                textAlign='center'
                overflow='auto'
                marginTop='64px'
                padding='12'
              >
                <Table data-testid='data-table'>
                  <Thead zIndex='1' position='sticky' top='-13'>
                    <Tr h='2em' textAlign='center' backgroundColor='neutral.50'>
                      <Th fontWeight='semibold' textAlign='center' w='24em'>
                        Application
                      </Th>
                      <Th fontWeight='semibold' textAlign='center'>
                        Speks
                      </Th>
                      <Th fontWeight='semibold' textAlign='center'>
                        Dock
                      </Th>
                      <Th fontWeight='semibold' textAlign='center'>
                        Spotlight
                      </Th>
                      <Th fontWeight='semibold' textAlign='left'>
                        Comments/Exception
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(status)
                      .filter((featureName) =>
                        featureName.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                      .map((featureName) => {
                        return (
                          <Tr key={featureName} h='5em'>
                            <Td width='10vw' borderBottom='none'>
                              <Flex
                                alignItems='center'
                                justifyContent='start'
                                gap='1em'
                                marginLeft='1em'
                              >
                                {iconsList[
                                  featureName.toLowerCase().replace(/\s+/g, '_')
                                ] && (
                                  <img
                                    src={
                                      iconsList[
                                        featureName.toLowerCase().replace(/\s+/g, '_')
                                      ]
                                    }
                                    alt='logo'
                                    height='44px'
                                    width='44px'
                                  />
                                )}
                                <span>{featureName}</span>
                              </Flex>
                            </Td>
                            <RenderTableCell features={status[featureName]['icons']} />
                            <RenderTableCell features={status[featureName]['dock']} />
                            <RenderTableCell
                              features={status[featureName]['spotlight']}
                            />
                            <Td borderBottom='none' w='16em' textAlign='left'>
                              <div>
                                <DescriptionWithLinks
                                  description={getDescription(featureName)}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </Flex>
            </Flex>
          )
        ) : (
          <AppCompatibilityOTP setValidatedUser={setValidatedUser} />
        )}
        <Flex
          direction='column'
          fontSize='14px'
          fontWeight='bold'
          color='#FFFFFF'
          position='absolute'
          top='30px'
          right='42px'
          bottom='29px'
          textAlign='right'
        >
          <div style={{marginRight: '149px'}}>Not a customer?</div>
          <div style={{marginRight: '31px', marginTop: '-21px'}}>
            <DSLink
              data-testid='resend-btn'
              href='https://spekit.co/demo-request/'
              color={'#FFFFFF'}
            >
              Request a demo
            </DSLink>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default AppCompatibilityStatus;
