import {Flex, Text, Heading, DSButton as Button, Image} from 'spekit-ui';
import SpekitLogo from '../../images/new-spekit-logo.svg';
import {useHistory} from 'react-router-dom';
export default function NotFound() {
  const history = useHistory();
  return (
    <Flex flexDirection='column' alignItems='center' justifyContent='center' h='100vh'>
      <Image
        src={SpekitLogo}
        alt='Spekit logo'
        position='absolute'
        left={20}
        top={20}
        h='33px'
      />
      <Text
        bgGradient='linear(to-r, pink.500, primary.500)'
        bgClip='text'
        fontSize='120px'
        fontWeight='bold'
      >
        404
      </Text>
      <Heading as='h1' color='neutral.800' fontWeight='semibold' mt={16}>
        This page could not be found
      </Heading>
      <Flex direction='column' textAlign='center' mt={26}>
        <Text variant='body2' color='neutral.800' fontWeight='normal'>
          The page you are trying to access does not exist or may have moved.
        </Text>
        <Text variant='body2' color='neutral.800' fontWeight='normal'>
          Please go back and try again.
        </Text>
      </Flex>
      <Button
        colorScheme='primary'
        size='small'
        variant='contained'
        mt={42}
        onClick={() => history.goBack()}
      >
        Return to previous page
      </Button>
    </Flex>
  );
}
