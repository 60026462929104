import React from 'react';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
} from '../../Popover';

import {Text} from '../../Typography';
import {Box} from '../../Layout';
import {Link} from '../../Link';
import {Button} from '../../Button';
import {Image} from '../../Image';

import SpekitLogo from './spek-icon.svg';

export const EmbeddedSpekPopover = () => {
  const focusRef = React.useRef<HTMLButtonElement>(null);
  return (
    <Popover
      placement='bottom-start'
      returnFocusOnClose={false}
      initialFocusRef={focusRef}
    >
      <PopoverTrigger>
        <Button
          background={'none !important'}
          p={0}
          m={0}
          variant='ghost'
          colorScheme='primary'
          size='small'
          display='flex'
          gap={4}
        >
          <Text color='primary.500' fontSize={'tiny'}>
            Do you want to embed this Spek?
          </Text>
          <Image src={SpekitLogo} alt='Embedded Spek Icon' h='18px' w='18px' />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton role='button' top={14} right={14} />
        <PopoverHeader textAlign={'left'}>Embedding a Spek</PopoverHeader>
        <PopoverBody>
          {/* popover requires focus to close on blur. if you don't autofocus it takes 2 clicks. this dummy button traps focus in an accessible way without an ugly focus outline */}
          <button
            aria-hidden='true'
            type='button'
            style={{
              lineHeight: 0,
              height: 0,
              opacity: 0,
              pointerEvents: 'none',
              userSelect: 'none',
              position: 'absolute',
            }}
            tabIndex={-1}
            ref={focusRef}
            aria-label='Embedded Spek Popover content'
          />
          <Box textAlign={'left'}>
            <Text fontSize='small' display='flex' alignItems='center'>
              Spek icons{' '}
              <Image ml={4} mr={4} src={SpekitLogo} alt='Spek icon' h='18px' w='18px' />{' '}
              will appear if:
            </Text>
            <Box pl='1.5rem' as='ul'>
              <Box as='li'>
                Spek belongs to a Topic that is linked to the domain URL you want to embed
                on
              </Box>
              <Box as='li'>Spek title exactly matches text on the page</Box>
            </Box>
            <Text fontSize='small'>For additional help, learn more about:</Text>
            <Box pl='1.5rem' as='ul' mb={0}>
              <Box as='li'>
                <Link
                  href='https://help.spekit.com/hc/en-us/articles/9922580438299-How-do-I-create-Speks-#h_01H43YC6MEWNE5NB3X09SWGHAN'
                  isExternal
                  role='link'
                >
                  Embedding Speks
                </Link>
              </Box>
              <Box as='li'>
                <Link
                  role='link'
                  href='https://help.spekit.com/hc/en-us/articles/9922580438299-How-do-I-create-Speks-#h_01H43YD7TAK3B0WWZ9877YMCYF'
                  isExternal
                >
                  Embedding Speks on Salesforce
                </Link>
              </Box>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
