interface IMaxDimensions {
  maxWidth: string;
  maxHeight: string;
}

interface IDefaultDimensions {
  height: string;
  width: string;
  marginTop?: string;
}

export const getBrandedHeightAndWidth = (
  maxDimensions: IMaxDimensions,
  defaultDimensions: IDefaultDimensions,
  logoUrl?: string | null
) => {
  const isBranded = Boolean(logoUrl);
  if (isBranded) return maxDimensions;
  return defaultDimensions;
};
