import React from 'react';
import {useHistory} from 'react-router-dom';
import {Flex, Image, Heading, Text, DSButton as Button} from 'spekit-ui';
import {useErrorBoundary} from 'react-error-boundary';
import errorFallback from '../images/error-fallback-illustration.svg';

interface IErrorFallbackProps {
  isAuthenticated: boolean;
  redirectTo?: string;
}

export const ErrorFallback = ({isAuthenticated, redirectTo}: IErrorFallbackProps) => {
  const history = useHistory();
  const {resetBoundary} = useErrorBoundary();

  const handleTryAgain = () => {
    if (redirectTo) {
      history.push(redirectTo);
      resetBoundary();
      return;
    }

    history.goBack();
  };

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height={isAuthenticated ? '100%' : '100vh'}
    >
      <Image src={errorFallback} alt='Error fallback illustration' />
      <Heading as='h4' fontWeight='bold' mt={24}>
        Something went wrong
      </Heading>
      <Text variant='body2' mt={12} mb={32} textAlign='center' sx={{textWrap: 'balance'}}>
        An unexpected error has occurred. Please go back and try again.
      </Text>
      <Button size='medium' variant='outlined' onClick={handleTryAgain}>
        Return to previous page
      </Button>
    </Flex>
  );
};
